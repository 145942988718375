import React from 'react';
import {IndexContent} from '../components/indexContent';

function Index() {
    return (
        <IndexContent lang="eu" />
    );
}
 
export default Index;
